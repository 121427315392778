<template>
  <div class="page page-login">
    <template v-if="!saved">
      <div class="forget-box bg-white">
        <h2 class="text-center">重置密码</h2>
        <div class="v-pa-lg v-mt-lg">
          <a-steps :current="currentStep" size="small">
            <a-step title="验证身份" />
            <a-step title="设置新密码" />
            <a-step title="重置成功" />
          </a-steps>
          <div class="step-content" style="margin: 60px auto 16px; width: 300px">
            <a-form-model ref="ruleForm" :model="form" autocomplete="off">
              <a-form-model-item prop="phone" v-show="currentStep === 0">
                <a-input size="large" v-model="form.phone" placeholder="手机号码" ref="phone" @pressEnter="nextStep" autocomplete="off">
                  <a-icon slot="prefix" type="mobile" />
                </a-input>
              </a-form-model-item>
              <a-form-model-item prop="smscode" v-show="currentStep === 0">
                <a-input size="large" type="number" v-model="form.smscode" placeholder="短信验证码" ref="smscode" :max-length="6" @pressEnter="nextStep" autocomplete="off">
                  <a-icon slot="prefix" type="mail" />
                  <span slot="suffix" class="sms-send text-primary cursor-pointer" @click="showBox" v-if="!verified">发送验证码</span>
                  <span slot="suffix" class="sms-send text-grey" v-else>
                    重新发送（
                    <i>{{ second }}</i>
                    s）
                  </span>
                </a-input>
              </a-form-model-item>
              <a-form-model-item prop="newPassword" v-show="currentStep === 1">
                <a-input-password size="large" v-model="form.newPassword" placeholder="输入密码" ref="newPassword" @pressEnter="nextStep">
                  <a-icon slot="prefix" type="lock" />
                </a-input-password>
                <div style="color: #aaa; font-size: 12px; line-height: 1; margin-top: 8px; margin-left: 4px">密码不低于6位，必须包含英文和数字</div>
              </a-form-model-item>
              <a-form-model-item prop="newPassword2" v-show="currentStep === 1">
                <a-input-password size="large" v-model="form.newPassword2" placeholder="再次输入密码" ref="newPassword2" @pressEnter="nextStep">
                  <a-icon slot="prefix" type="lock" />
                </a-input-password>
              </a-form-model-item>
            </a-form-model>
            <template v-if="currentStep !== 2">
              <div class="text-center">
                <a-button class="full-width" type="primary" size="large" @click="nextStep" :loading="saving">下一步</a-button>
              </div>
              <div class="text-center v-my-md">
                <router-link class="hover-line" :to="{ path: 'login' }">返回登录</router-link>
              </div>
            </template>
            <a-result status="success" title="密码重置成功!" sub-title="请妥善保管您的账户信息" v-else>
              <template #extra>
                <a-button key="login" type="primary" @click="$router.push('login')">去登陆</a-button>
                <a-button key="index" @click="goHome">返回首页</a-button>
              </template>
            </a-result>
            <Verify @success="verifySuccess" :mode="'pop'" :captchaType="'blockPuzzle'" :imgSize="{ width: '330px', height: '155px' }" ref="verify"></Verify>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="bg-white" style="width: 600px; margin: 0 auto">
        <a-result status="success" title="密码重置成功" sub-title="请妥善保管您的账户信息">
          <template #extra>
            <a-button key="login" type="primary" @click="$router.push('login')">去登陆</a-button>
            <a-button key="index" @click="goHome">返回首页</a-button>
          </template>
        </a-result>
      </div>
    </template>
  </div>
</template>

<script>
import { validResetPwd, submitResetPwd, resetSms } from '@/api/user'
import Verify from './common/captcha/Verify'
export default {
  components: {
    Verify,
  },
  data() {
    return {
      checked: false,
      currentStep: 0,
      form: {
        phone: '',
        smscode: '',
        newPassword: '',
        newPassword2: '',
      },
      saving: false,
      saved: false,
      second: 60,
      verified: false,
      timer: null,
    }
  },
  methods: {
    showBox() {
      let { phone } = this.form
      this.$message.destroy()
      if (!this.$utils.isValidPhone(phone)) {
        this.$message.warning('请输入正确的手机号码')
        this.$refs.phone.focus()
        return
      }
      this.$refs.verify.show()
    },
    verifySuccess(params) {
      this.$message.destroy()
      let { phone } = this.form
      resetSms({ phone, captchaVerification: params.captchaVerification })
        .then(({ data }) => {
          if (data.success) {
            this.$message.success('验证码已发送至手机')
            this.verified = true
            this.second = 60
            this.startCountdown()
          } else {
            this.$message.warning(data.msg)
          }
        })
        .catch(err => {
          if (err) {
            this.$message.warning(err)
          }
        })
    },
    startCountdown() {
      this.clearTimer()
      let vm = this
      const countdown = () => {
        vm.second--
      }
      vm.timer = setInterval(() => {
        countdown()
      }, 1000)
    },
    clearTimer() {
      if (this.timer) {
        clearInterval(this.timer)
      }
    },
    nextStep() {
      let { phone, newPassword, newPassword2, smscode } = this.form
      this.$message.destroy()
      let currentStep = this.currentStep
      if (currentStep === 0) {
        if (!this.$utils.isValidPhone(phone)) {
          this.$message.warning('请输入正确的手机号码')
          this.$refs.phone.focus()
          return
        }
        if (smscode === '') {
          this.$refs.smscode.focus()
          this.$message.warning('请输入短信验证码')
          return
        }
        this.saving = true
        validResetPwd({ phone, smscode })
          .then(({ data }) => {
            this.saving = false
            if (data.success) {
              this.currentStep = 1
              this.clearTimer()
            } else {
              this.$message.warning(data.msg)
            }
          })
          .catch(err => {
            if (err) {
              this.$message.warning(err)
            }
            this.saving = false
          })
      } else if (currentStep === 1) {
        if (!this.$utils.isValidPwd(newPassword)) {
          this.$refs.newPassword.focus()
          this.$message.warning('请输入包含英文字母和数字且不低于6位的密码')
          return
        }
        if (newPassword2 === '') {
          this.$refs.newPassword2.focus()
          this.$message.warning('请输入确认密码')
          return
        }
        if (newPassword !== newPassword2) {
          this.$refs.newPassword2.focus()
          this.$message.warning('两次输入的密码不一致')
          return
        }
        this.saving = true
        submitResetPwd({ phone, pwd: newPassword, smscode })
          .then(({ data }) => {
            if (data.success) {
              this.saved = true
              this.second = 60
              this.currentStep = 2
            } else {
              this.$message.warning(data.msg)
            }
            this.saving = false
          })
          .catch(err => {
            if (err) {
              this.$message.warning(err)
            }
            this.saving = false
          })
      }
    },
    onChange(e) {
      this.checked = e.target.checked
    },
    onSubmit() {
      this.saving = true
      setTimeout(() => {
        this.saving = false
        this.saved = true
      }, 1200)
    },
  },
}
</script>
